import { useState } from "react";
import Scroll from "react-scroll";
import Hero from "../components/classic/home/hero/hero";
import Footer from "../components/footer/footer";

const Element = Scroll.Element;

function ClassicHomePage() {
  const [openDownload, setOpenDownload] = useState(false);

  return (
    <div className="bg-darkslate">
      <Hero openDownload={openDownload} setOpenDownload={setOpenDownload} />
      <Footer />
    </div>
  );
}

export default ClassicHomePage;
import React, { Suspense } from 'react';
import Scroll from "react-scroll";
// import MainButtonRegular from '../buttons/main-button-regular';
const scroller = Scroll.scroller;

function Hero({ setOpenDownload }) {
  const handleScrollTo = () => {
    // Somewhere else, even another file
    scroller.scrollTo('discover', {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  };

  return (
    <div className="md:hero pb-32 relative overflow-clip">
      <div className="absolute top-0 left-0 min-w-full md:h-auto h-full">
        <Suspense height={1000}>
          <iframe
            height="1200"
            width="100%"
            src="https://www.youtube.com/embed/tDSqVl77wnE?autoplay=1&mute=1&enablejsapi=1&controls=0&showinfo=0&autohide=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=tDSqVl77wnE"
            title="Background video short"
            frameBorder="0"
          />
        </Suspense>
        <div className="bg-slate-900 opacity-50 min-w-full min-h-[765px] h-full absolute top-0 left-0">
        </div>
      </div>
      <div className="container mx-auto relative md:mt-12 mt-[4rem]">
        <div className="max-w-xl sm:w-auto mx-auto p-4 md:mb-8 mb-0">
          <img
            src="images/bombergrounds-logo.webp"
            className="md:w-[420px] w-5/6 mx-auto"
            alt="Bombergrounds"
            width="420"
            height="170"
          />
        </div>
        {/* <div className="md:ml-5 mx-auto px-10 py-10 w-full">
          <p className="font-style-game md:text-[25px] text-center text-white text-shadow-normal uppercase">The most chaotic and fun multiplayer game of 2022</p>
          <p className="font-style-game md:text-[55px] text-2xl text-center text-white text-shadow-normal">DOWNLOAD NOW</p>
        </div> */}
        <div className="mx-auto px-10 w-full flex justify-center gap-1">
          <img
            src="images/icon-star.webp"
            className="md:w-[36px] w-[24px] h-auto"
            alt="Star"
            width="36"
            height="36"
          />
          <img
            src="images/icon-star.webp"
            className="md:w-[36px] w-[24px] h-auto"
            alt="Star"
            width="36"
            height="36"
          />
          <img
            src="images/icon-star.webp"
            className="md:w-[36px] w-[24px] h-auto"
            alt="Star"
            width="36"
            height="36"
          />
          <img
            src="images/icon-star.webp"
            className="md:w-[36px] w-[24px] h-auto"
            alt="Star"
            width="36"
            height="36"
          />
          <img
            src="images/icon-star-half.webp"
            className="md:w-[36px] w-[24px] h-auto"
            alt="Star"
            width="36"
            height="36"
          />
        </div>
        <div className="mx-auto w-full flex justify-center gap-1 mb-4">
          <p className="font-style-game text-white">Average of 40k+ Reviews</p>
        </div>
        <div className="md:max-w-2xl max-w-[200px] sm:w-auto mx-auto p-4 grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2">
          <a
            href="https://store.steampowered.com/app/1104450/Bombergrounds_Battle_Royale/"
            target="_blank"
            rel="noreferrer"
            className="group"
          >
            <img
              src="images/steam-download.webp"
              className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out"
              alt="Steam"
              width="200"
              height="60"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/bombergrounds-battle-royale/id1476733308"
            target="_blank"
            rel="noreferrer"
            className="group"
          >
            <img
              src="images/appstore-download.webp"
              className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out"
              alt="App Store"
              width="200"
              height="60"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.Giganticduck.Bombergrounds"
            target="_blank"
            rel="noreferrer"
            className="group"
          >
            <img
              src="images/playstore-download-new.webp"
              className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out"
              alt="Play Store"
              width="200"
              height="60"
            />
          </a>
        </div>
        {/* <div className="max-w-xl sm:w-auto mx-auto p-4 md:gap-8 gap-2 flex justify-center">
          <MainButtonRegular text="Download Now" onClick={() => setOpenDownload(true)} />
        </div>
        <div className="max-w-xl sm:w-auto mx-auto p-4 md:gap-8 gap-2 flex justify-center">
          <img
            src="images/icon-playstore.webp"
            className="w-[48px]"
            alt="Play Store"
          />
          <img
            src="images/icon-appstore.webp"
            className="w-[48px]"
            alt="Play Store"
          />
          <img
            src="images/icon-steam.webp"
            className="w-[48px]"
            alt="Play Store"
          />
        </div> */}
      </div>
      <div
        className="flex flex-col cursor-pointer justify-center absolute left-[50%] -translate-x-2/4 md:hover:bottom-20 md:bottom-16 hover:bottom-8 bottom-4 transition-all duration-150 ease-in-out"
        onClick={() => handleScrollTo()}
      >
        <p className="uppercase font-style-game text-white text-center md:text-[24px] text-16px -mb-2">Learn More</p>
        <img
          src="images/animals/animal-ui-arrow.webp"
          alt="Scroll Down"
          className="rotate-90 md:w-[64px] w-[48px] mx-auto"
          width="64"
          height="64"
        />
      </div>
    </div>
  );
}

export default Hero;
import React from 'react';
import { AnimalsInformation } from '../../../../utils/animals';

function AnimalInformation({ currentAnimalIndex, setCurrentAnimalIndex }) {
  const animals = AnimalsInformation;

  const handleBackClick = () => {
    if (currentAnimalIndex > 0) setCurrentAnimalIndex(currentAnimalIndex - 1);
  }

  const handleNextClick = () => {
    if (currentAnimalIndex < (animals.length - 1)) setCurrentAnimalIndex(currentAnimalIndex + 1);
  }

  return (
    <div className="block gap-8">
      <div className="md:w-4/6 w-full mx-auto p-4 md:pr-12 md:mt-0 mt-8 text-center">
      {
        animals.length > 0 && animals.map((animal, index) => {
          if (!animal) return <></>;
          return (
            <div
              key={animal.name}
              className={currentAnimalIndex === index ? 'block' : 'hidden'}
            >
              <p className="font-style-game md:text-5xl text-3xl text-white uppercase tracking-wider">
                { animal.name }
              </p>
              <p className={`font-style-game md:text-3xl text-2xl uppercase tracking-wider text-${animal.rarity}`}>
                { animal.rarity } Animal
              </p>
            </div>
          )
        })
      }
      </div>
      <div className="md:w-4/6 mx-auto w-full md:h-[440px] h-auto rounded-xl relative flex">
      {
        animals.length > 0 && animals.map((animal, index) => {
          if (!animal) return <></>;
          return (
            <div
              key={animal.name}
              className={`rounded-lg w-4/6 mx-auto gap-4 relative ${currentAnimalIndex === index ? 'block' : 'hidden'}`}
            >
              <img
                src={`images/animals/${animal.name}.webp`}
                alt={`animal ${animal ? animal.name : 'Not Found'}`}
                className="md:w-5/6 w-auto mx-auto animate-float relative md:top-0 top-[20px] md:mt-0 mt-8 z-[5]"
                width="380"
                height="468"
              />
              <img
                src={`images/animals/animal-shadow-wide.webp`}
                alt="Animal shadow"
                className="md:w-[250px] w-[180px] animate-shadow-float opacity-50 mx-auto absolute md:-bottom-10 -bottom-[30px]"
                width="380"
                height="468"
              />
            </div>
          )
        })
      }
      <div className="absolute left-0 self-center flex justify-start">
        <img
          src="images/animals/animal-ui-arrow.webp"
          className="-scale-x-100 cursor-pointer self-center z-[5] md:w-full w-5/6"
          alt="Arrow Left"
          onClick={handleBackClick}
          width="100"
          height="100"
        />
      </div>
      <div className="absolute right-0 flex self-center justify-end z-[5]">
        <img
          src="images/animals/animal-ui-arrow.webp"
          alt="Arrow Right"
          className="cursor-pointer md:w-full w-5/6"
          onClick={handleNextClick}
          width="100"
          height="100"
        />
      </div>
      </div>
    </div>
  );
}

export default AnimalInformation;
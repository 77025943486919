import React from 'react';
import { scroller } from 'react-scroll';
import MainButton from '../../../buttons/main-button';
// import RegularButton from '../buttons/regular-button';

function Discover() {
  const handleScrollTo = (element) => {
    // Somewhere else, even another file
    scroller.scrollTo(element, {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    })
  };

  return (
    <div className="bg-slate-900 relative overflow-hidden md:pb-0 pb-40">
      <img
        src="images/bg-discover.webp"
        alt="Discover background"
        className="absolute z-0 bottom-0 md:left-0 -left-1/2 md:w-full w-auto h-full max-w-[10000%]"
      />
      <img
        src="images/discover-cat.webp"
        alt="Discover Cat"
        className="absolute z-0 bottom-0 right-0 md:left-[unset] left-1/2 sxl:-translate-x-0 md:translate-x-1/4 -translate-x-1/2 md:w-auto w-1/2 md:h-full h-auto"
      />
      <div className="container mx-auto max-w-[1280px] sxl:py-24 py-16 relative">
        <div className="w-11/12 mx-auto">
          <h2 className="font-style-game font-medium tracking-wider uppercase md:text-6xl text-4xl text-center text-white">
            Discover Bombergrounds
          </h2>
        </div>
        {/* <div className="mx-auto flex justify-center mt-6 gap-2">
          <div className="h-3 w-4 bg-white border-[1px] border-b-[3px] border-black"></div>
          <div className="h-4 w-12 bg-white border-[1px] border-b-[3px] border-black"></div>
          <div className="h-3 w-4 bg-white border-[1px] border-b-[3px] border-black"></div>
        </div> */}
        <div className="mx-auto w-auto py-4 font-lato mt-8 md:px-0 px-8">
          <p className="text-gray text-center md:text-[28px] text-md text-white font-style-game">Bombergrounds is a free-to-play multiplayer action-based bomber game. Engage in solo- and team battles in both fun and competitive game modes, or simply find a new friend in the Bombergrounds community.</p>
          <p className="text-gray text-center md:text-[28px] text-md text-white font-style-game mt-2">Will you be victorious? Lets find out!</p>
        </div>
        <div className="mt-8 flex md:flex-row flex-col justify-center gap-8">
          <MainButton text="Animals" onClick={() => handleScrollTo('animals')} />
          <MainButton text="Events" onClick={() => handleScrollTo('modes')} />
          <MainButton text="Super Cross-play" onClick={() => handleScrollTo('crossplay')} />
        </div>
        {/* <div className="mt-8 flex md:flex-row flex-col justify-center gap-8">
          <RegularButton text="Available Characters" onClick={() => handleScrollTo('animals')} />
          <RegularButton text="Game Modes" onClick={() => handleScrollTo('modes')} />
          <RegularButton text="Super-Crossplay" onClick={() => handleScrollTo('crossplay')} />
        </div> */}
      </div>
    </div>
  );
}

export default Discover;
import React, { Suspense } from 'react';
import Scroll from "react-scroll";
// import MainButtonRegular from '../buttons/main-button-regular';
const scroller = Scroll.scroller;

function Hero({ setOpenDownload }) {

  return (
    <div className="relative overflow-hidden">
      <div className="relative flex justify-center lg:justify-end lg:translate-x-[20vh]">
        <div className="relative flex-shrink-0">
          <img
              src="images/classic/splash-art.webp"
              alt="Bombergrounds Classic"
              className="sm:max-lg:w-[100vh] h-[60vh] lg:h-[100vh]"
          />
          <div className="sm:max-lg:invisible absolute top-0 left-0 bottom-0 lg:min-w-[600px] bg-gradient-to-r from-darkslate translate-x-[-1px]"></div>
          <div className="lg:invisible absolute left-0 right-0 bottom-0 min-h-[200px]  bg-gradient-to-t from-darkslate"></div>
        </div>
      </div>
      <div className="lg:absolute lg:top-[35%] lg:left-[9%] lg:right-[60%]">
        <div className='flex-row mx-6 my-16 lg:m-0 text-center lg:text-left'>
          <h1 className="font-style-game-title text-white text-5xl lg:text-7xl">
            BOMBERGROUNDS: CLASSIC <span className="text-[#FCBD10]">RETURNS</span>
          </h1>

          <p className="font-style-game text-2xl pt-8 text-white">
            No Pay2Win, Pure Skill—Blast Your Way to Victory!
          </p>

          <div className='flex justify-center lg:justify-start md:max-w-2xl max-w-[200px] sm:w-auto py-6 grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-2'>
            <a
              href="https://store.steampowered.com/app/2938020/Bombergrounds_Classic/"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="images/steam-download.webp"
                className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out"
                alt="Steam"
                width="200"
                height="60"
              />
            </a>
            <a
              href="https://apps.apple.com/app/bombergrounds-classic/id6661033230"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="images/appstore-download.webp"
                className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out"
                alt="App Store"
                width="200"
                height="60"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.Giganticduck.Bombergrounds.Classic"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="images/playstore-download-new.webp"
                className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out"
                alt="Play Store"
                width="200"
                height="60"
              />
            </a>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Hero;
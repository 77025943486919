import { useState } from "react";
import Subhero from "../components/subhero/subhero";
// import Media from "../components/reborn/Home/media/media";
import Modes from "../components/reborn/Home/modes/modes";
import Scroll from "react-scroll";
import Crossplay from "../components/reborn/Home/crossplay/crossplay";
import Discover from "../components/reborn/Home/discover/discover";
import Download from "../components/reborn/Home/download/download";
import Animals from "../components/reborn/Home/animals/animals";
// import Create from "../components/create/create";
import Footer from "../components/footer/footer";
import Subfooter from "../components/footer/subfooter";
import Hero from "../components/reborn/Home/hero/hero";

const Element = Scroll.Element;

function RebornHomePage() {
  const [openDownload, setOpenDownload] = useState(false);

  return (
    <div>
      <Hero openDownload={openDownload} setOpenDownload={setOpenDownload} />
      <Subhero />
      <Element name="discover">
        <Discover />
      </Element>
      <Element name="animals">
        <Animals />
      </Element>
      {/* <Create /> */}
      <Element name="modes">
        <Modes />
      </Element>
      <Element name="crossplay">
        <Crossplay />
      </Element>
      {/* <Element name="media">
        <Media />
      </Element> */}
      <Subfooter />
      <Footer />
      <Download openDownload={openDownload} setOpenDownload={setOpenDownload} />
    </div>
  );
}

export default RebornHomePage;